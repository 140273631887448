<template>
  <div class="main-wrapper">
    <Main />
    <Exchange />
    <CountryInfo />
    <WhatIsWesternSahara />
    <Gallery />
    <History />
    <Politics />
    <ProposedAutonomy />
    <NationalHolidays />
  </div>
</template>

<script>
import Main from './main.vue';
import Exchange from './../exchange-rates.vue';
import CountryInfo from './countrysInfo.vue';
import WhatIsWesternSahara from './westernSahara.vue';
import Gallery from './gallerys.vue';
import History from './historys.vue';
import Politics from './politic.vue';
import ProposedAutonomy from './proposedAutonomys.vue';
import NationalHolidays from './nationalHolidays.vue';

export default {
  components: {
    Main,
    Exchange,
    CountryInfo,
    WhatIsWesternSahara,
    Gallery,
    History,
    Politics,
    ProposedAutonomy,
    NationalHolidays,
  },
};
</script>

<style lang="scss" scoped>
</style>
