<template>
  <div class="howGetBusinessName">
    <div class="howGetBusinessName-wrapper">
      <div class="howGetBusinessName-content">
        <div>
          <div class="block">
            <h2>HOW TO GET A BUSINESS NAME?</h2>
            <div class="block-indent">
              <p class="text">
                Applying for a business name is quick and easy online. If you
                have limited computer skills, paper applications are also
                available. This information sets out how to register a business
                name. It covers:
              </p>
              <ul class="text">
                <li>General information about business names;</li>
                <li>Choosing a business name;</li>
                <li>Reserving business names;</li>
                <li>Registering business name;</li>
                <li>Keeping your details up to date;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.howGetBusinessName {
  background: url("../../../assets/howGetBusinessName/howGetBusinessNameBg.jpg");
  background-repeat: no-repeat;
  background-size: auto;
}
.howGetBusinessName-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
    justify-content: center;
  }
}
.block {
  background: #af8e6f;
  padding: 90px 128px 90px 128px;
  position: relative;
  margin-right: 200px;
  position: relative;
  top: -260px;
  z-index: 222;
  width: 630px;
  @media (max-width: 1100px) {
    margin-right: 0;
    top: 0;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: auto;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 56px;
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
ul {
  margin: 0;
  padding: 0;
  margin-bottom: 46px;
  li {
    list-style-type: none;
    margin-bottom: 16px;
    margin-left: 40px;
    position: relative;
    &::before {
      content: "";
      width: 20px;
      height: 2px;
      background: #fbfbfb;
      position: absolute;
      top: 12px;
      left: -37px;
    }
  }
}
.howGetBusinessName-content {
  p {
    padding-bottom: 20px;
  }
}
</style>
