<template>
  <div class="resolution">
    <div class="resolution-wrapper">
      <div class="resolution-content">
        <div>
          <h2>WHAT IS RESOLUTION?</h2>
          <div class="block">
            <p class="text">
              Resolution is the use of appropriate tools in the case of troubled
              banks facing financial distress.
            </p>
            <p class="text">
              Banking resolution is the process of restructuring a bank by a
              resolution authority, using the tools and the resolution powers to
              ensure the continuity of the critical function of the bank (eg.
              deposits, payment transactions), to restore its viability, wholly
              or partially, and to liquidate the residual part of the credit
              institution under normal insolvency proceedings.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.resolution-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}

.no-line {
  &::before {
    display: none;
  }
}
.resolution-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.resolution-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
