<template>
  <div class="main-wrapper">
    <Exchange />
    <GovermentSecurities />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import GovermentSecurities from "./govermentSecurities";

export default {
  components: {
    Exchange,
    GovermentSecurities,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
