<template>
  <div class="cryptocurrency">
    <div class="cryptocurrency-wrapper">
      <div class="cryptocurrency-content">
        <div style="width: 80%">
          <div class="block">
            <h2>CRYPTOCURRENCY</h2>
            <div class="block-indent">
              <p class="text">
                Is a form of digital asset based on a network that is
                distributed across a large number of computers. This
                decentralized structure allows them to exist outside the control
                of governments and central authorities.
              </p>
              <p class="text">
                The first blockchain-based cryptocurrency was Bitcoin, which
                still remains the most popular and most valuable. Today, there
                are thousands of alternate cryptocurrencies with various
                functions and specifications. Some of these are clones or forks
                of Bitcoin, while others are new currencies that were built from
                scratch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blockTwo">

      <a style="text-decoration: none"
          download="The-Crypto-Company-Act-2021.pdf"
          href="The-Crypto-Company-Act-2021.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          The Crypto Company Act 2020
        </p>
      </a>

      <a style="text-decoration: none"
          download="Companies_Act_2018.pdf"
          href="Companies_Act_2018.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Companies Act 2018
        </p>
      </a>

      <a style="text-decoration: none"
          download="Opening_an_account_with_us.pdf"
          href="Opening_an_account_with_us.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Opening an account with us
        </p>
      </a>

      <a style="text-decoration: none"
          download="WSADR_Conditions.pdf"
          href="WSADR_Conditions.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          WSADR Conditions
        </p>
      </a>

      <a style="text-decoration: none"
          download="Application_form.pdf"
          href="Application_form.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Application form
        </p>
      </a>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.cryptocurrency-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    padding-top: 35px;
    padding-top: 0px;
    max-width: 1024px;
  }
}
.block {
  background: #af8e6f;
  padding: 100px 0px 100px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1100px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-indent {
  padding-right: 200px;
  margin-top: 50px;
  @media (max-width: 740px) {
    padding-right: 20px;
    margin-top: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  width: 65%;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
    width: 100%;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.cryptocurrency-content {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  p {
    padding-bottom: 20px;
  }
}

.blockTwo {
  background: #fff;
  padding: 20px 20px 20px 200px;
  position: relative;
  z-index: 222;
  width: 30%;
  @media (max-width: 740px) {
    width: 87%;
    padding: 20px 20px 20px 20px;
  }
}

.text-green {
  font-family: 'NotoSans-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}
</style>
