<template>
  <div class="main-wrapper">
    <Exchange />
    <Resolution />
    <ResolutionAim />
    <Reasons />
    <ResolutionAt />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import Resolution from "./resolution";
import ResolutionAim from "./resolutionAim";
import Reasons from "./reasons";
import ResolutionAt from "./resolutionAt";

export default {
  components: {
    Exchange,
    Resolution,
    ResolutionAim,
    Reasons,
    ResolutionAt,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
