<template>
  <div class="national-holidays">
    <div class="national-holidays-wrapper">
      <div class="national-holidays-content">
        <div>
          <h2>NATIONAL HOLIDAYS</h2>
          <div class="block">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column
                prop="date"
                label="Date"
                width="390"
                style="background-color: red"
              >
              </el-table-column>
              <el-table-column prop="name" label="Name" width="390">
              </el-table-column>
              <el-table-column prop="event" label="Original event" width="390">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "February 27",
          name: "Independence Day",
          event: "Proclamation of the Western Sahara in Bir Lehlou, 1976",
        },
        {
          date: "May 10",
          name: "Foundation of the Polisario Front",
          event: "Founded 1973",
        },
        {
          date: "May 20",
          name: "May 20 Revolution",
          event: "Start of the armed struggle against Spain in 1973",
        },
        {
          date: "June 5",
          name: "Day of the Disappeared",
          event: "Remembering missing Sahrawis",
        },
        {
          date: "June 9",
          name: "Day of the Martyrs",
          event: "Day on which El-Ouali died in 1976",
        },
        {
          date: "June 17",
          name: "Zemla Intifada",
          event: "Harakat Tahrir riots in El-Aaiun, 1970",
        },
        {
          date: "October 12",
          name: "Day of National Unity",
          event: "Celebrating the Ain Ben Tili Conference, 1975",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.national-holidays-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 150px;
  // height: 500px;
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #006942;
  position: relative;
  z-index: 222;
  margin: 0;
  padding-bottom: 56px;
  padding-left: 80px;
  @media (max-width: 850px) {
    padding-left: 20px;
    font-size: 24px;
    padding-bottom: 40px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
}
.el-table td,
.el-table th.is-leaf {
  background: red;
}
</style>
