<template>
  <div class="main-wrapper">
    <Exchange />
    <Contacts />
  </div>
</template>

<script>
import Exchange from "../exchange-rates.vue";
import Contacts from "./contact";

export default {
  components: {
    Exchange,
    Contacts,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
