import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home";

import WhatWeDo from "@/components/about/whatWeDo";
import InstitutionalFramework from "@/components/about/institutionalFramework";

import BankLicense from "@/components/ourServices/internationalBanking";
import BrokerageCompanies from "@/components/ourServices/brokerageCompanies";
import InsuranceCompanies from "@/components/ourServices/insuranceCompanies";
import TrustCompanies from "@/components/ourServices/trustCompanies";
import GamblingCompanies from "@/components/ourServices/gamblingCompanies";
import ServicesFees from "@/components/ourServices/servicesFees";
import BusinessNameInfo from "@/components/ourServices/businessNameInfo";
import ApplicationForm from "@/components/ourServices/applicationForm";
import CommunityBanking from "@/components/bankSupervision/communityBankingResources";
// import ListOfBanks from "@/components/bankSupervision/listOfBanks";
import Resolution from "@/components/resolution/resolution";
import GovermentSecurities from "@/components/resolution/govermentSecurities";
import PaymentSystem from "@/components/resolution/paymentSystem";
// import RegisterSearch from "@/components/registerSearch";
import Contacts from "@/components/contact";
import FinancialStability from "@/components/financialStability/financialStability";
import MacroPrudentialPolicies from "@/components/financialStability/macroPrudentialPolicies";
import MacroPrudentialStrategy from "@/components/financialStability/macroPrudentialStrategy";
import CryptoLicense from "@/components/ourServices/cryptoLicense";
import RegisteredAgents from "@/components/RegisteredAgents/index.vue";
import ListOfEntitles from "@/components/List of Entitles/List Of Entitles.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Home" },
  },
  {
    path: "/WhatWeDo",
    name: "WhatWeDo",
    component: WhatWeDo,
    meta: { title: "What we do" },
  },
  {
    path: "/InstitutionalFramework",
    name: "InstitutionalFramework",
    component: InstitutionalFramework,
    meta: { title: "InstitutionalFramework" },
  },
  {
    path: "/BankLicense",
    name: "BankLicense",
    component: BankLicense,
    meta: { title: "BankLicense" },
  },
  {
    path: "/BrokerageCompanies",
    name: "BrokerageCompanies",
    component: BrokerageCompanies,
    meta: { title: "BrokerageCompanies" },
  },
  {
    path: "/InsuranceCompanies",
    name: "InsuranceCompanies",
    component: InsuranceCompanies,
    meta: { title: "InsuranceCompanies" },
  },
  {
    path: "/TrustCompanies",
    name: "TrustCompanies",
    component: TrustCompanies,
    meta: { title: "TrustCompanies" },
  },
  {
    path: "/GamblingCompanies",
    name: "GamblingCompanies",
    component: GamblingCompanies,
    meta: { title: "GamblingCompanies" },
  },
  {
    path: "/ServicesFees",
    name: "ServicesFees",
    component: ServicesFees,
    meta: { title: "ServicesFees" },
  },
  {
    path: "/BusinessNameInfo",
    name: "BusinessNameInfo",
    component: BusinessNameInfo,
    meta: { title: "BusinessNameInfo" },
  },
  {
    path: "/ApplicationForm",
    name: "ApplicationForm",
    component: ApplicationForm,
    meta: { title: "ApplicationForm" },
  },
  {
    path: "/CommunityCompanyResources",
    name: "CommunityCompanyResources",
    component: CommunityBanking,
    meta: { title: "CommunityCompanyResources" },
  },
  // {
  //   path: "/ListOfLicensedCompanies",
  //   name: "ListOfLicensedCompanies",
  //   component: ListOfBanks,
  //   meta: { title: "ListOfLicensedCompanies" },
  // },
  {
    path: "/Resolution",
    name: "Resolution",
    component: Resolution,
    meta: { title: "Resolution" },
  },
  {
    path: "/GovernmentSecurities",
    name: "GovernmentSecurities",
    component: GovermentSecurities,
    meta: { title: "GovernmentSecurities" },
  },
  {
    path: "/PaymentSystem",
    name: "PaymentSystem",
    component: PaymentSystem,
    meta: { title: "PaymentSystem" },
  },
  // {
  //   path: "/RegisterSearch",
  //   name: "RegisterSearch",
  //   component: RegisterSearch,
  //   meta: { title: "RegisterSearch" },
  // },
  {
    path: "/FinancialStability",
    name: "FinancialStability",
    component: FinancialStability,
    meta: { title: "FinancialStability" },
  },
  {
    path: "/MacroPrudentialPolicies",
    name: "MacroPrudentialPolicies",
    component: MacroPrudentialPolicies,
    meta: { title: "MacroPrudentialPolicies" },
  },
  {
    path: "/MacroPrudentialStrategy",
    name: "MacroPrudentialStrategy",
    component: MacroPrudentialStrategy,
    meta: { title: "MacroPrudentialStrategy" },
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contacts,
    meta: { title: "Contact" },
  },
  {
    path: "/RegisteredAgents",
    name: "RegisteredAgents",
    component: RegisteredAgents,
    meta: { title: "RegisteredAgents" },
  },
  {
    path: "/CryptoLicense",
    name: "CryptoLicense",
    component: CryptoLicense,
    meta: { title: "CryptoLicense" },
  },

  {
    path: "/ListOfEntities",
    name: "ListOfEntitles",
    component: ListOfEntitles,
    meta: { title: "ListOfEntitles" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    // if (to.hash === '#News') return { x: 0, y: 700 }
    // if (to.hash === '#About') return { x: 0, y: 2000 }
    document.title = "Crasadr  | " + to.meta.title || "Crasadr";
    return { x: 0, y: 0 };
  },
});

export default router;
