<template>
  <div class="resolutionReasons">
    <div class="resolutionReasons-wrapper">
      <div class="resolutionReasons-content">
        <div>
          <h2>UNDERLYING REASONS FOR THE RESOLUTION</h2>
          <div class="block">
            <p class="text">
              The recent global financial crisis showed that the existing
              instruments available to supervisory authorities were not adequate
              to effectively deal with the new difficulties that banks were
              facing. Thus, authorities often used the public funds to avoid
              banks winding up, which would then have significant adverse
              effects impacts on the country's financial stability.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.resolutionReasons-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}

.no-line {
  &::before {
    display: none;
  }
}
.resolutionReasons-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
