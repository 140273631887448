<template>
  <div class="contacts">
    <div class="contacts-wrapper">
      <div class="contacts-content">
        <div>
          <h2>Our Trusted Partners</h2>
          <div class="block">
            <div class="contacts-item">
              <p class="text">
                For further information, please consult our esteemed network of trusted agents:
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="agents_list_wrapper">
        <div class="agent_block">
          <p class="agent_block_title">SADR Management and Advisory Corporation</p>
          <p class="agent_block_description">Explore comprehensive services and expert advice tailored for diverse needs at&nbsp;
            <a class="agent_block_description_link" target="_blank" href="https://advisorycorpws.com/">SADR Management and Advisory Corporation</a>.
          </p>
        </div>
        <div class="agent_block">
          <p class="agent_block_title">Clear Sky Network</p>
          <p class="agent_block_description">Connect with global professionals and secure efficient solutions through&nbsp;
            <a class="agent_block_description_link" target="_blank" href="https://www.clearsky-network.com/">Clear Sky Network</a>.
          </p>
        </div>
        <div class="agent_block">
          <p class="agent_block_title">Eternity Law</p>
          <p class="agent_block_description">Access specialized legal expertise for your business at&nbsp;
            <a class="agent_block_description_link" target="_blank" href="https://www.eternitylaw.com/">Eternity Law</a>.
          </p>
        </div>
        <div class="agent_block">
          <p class="agent_block_title">Ramsadvisory</p>
          <p class="agent_block_description">Discover innovative strategies and detailed guidance at&nbsp;
            <a class="agent_block_description_link" target="_blank" href="https://www.ramadvisory.co/">Ramsadvisory</a>.
          </p>
        </div>

        <div class="addition_details">
          For detailed queries and personalized service, we encourage you to reach out to our partner agencies directly through their respective websites.
        </div>

      </div>

    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.contacts {
  min-height: 59.7vh;
}
.contacts-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 0 50px 0 250px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  color: var(--Secondary-sand, #AF8E6F);
  font-family: Lato,sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  width: 1071px;
  @media (max-width: 740px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 0!important;
    width: 100%;
  }
}
.text-bold {
  font-weight: bold;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.contacts-item {
  display: flex;
  margin: 30px 0;
  @media (max-width: 740px) {
    margin: 0;
  }
}

.no-line {
  &::before {
    display: none;
  }
}
.contacts-content {
  display: flex;
  width: 80%;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
h2 {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 250px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-family: Lato,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0!important;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -85%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.agents_list_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;

  .agent_block {
    padding: 60px 0 60px 64px;
    width: 83%;
    background: #FBFBFB;
    margin-bottom: 20px;
    box-sizing: border-box;

    @media (max-width: 740px) {
      width: 100%;
      padding: 30px 16px;
    }

    .agent_block_title {
      color: var(--Basic-dark, #252827);
      font-family: Lato,sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 27px;

      @media (max-width: 740px) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    .agent_block_description {
      color: var(--Basic-dark, #252827);
      font-family: "Noto Sans",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 740px) {
        font-size: 14px;
      }

      .agent_block_description_link {
        color: var(--Secondary-dark-green, #006942);
        text-decoration: none;
      }
    }
  }

  .addition_details {
    color: var(--Secondary-sand, #AF8E6F);
    font-family: "Noto Sans",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    width: 83%;

    @media (max-width: 740px) {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
    }
  }
}

</style>
