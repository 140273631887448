<template>
  <div class="main-wrapper">
    <Exchange />
    <OverviewOfInternationalInsurance />
    <InsuranceAndBrokerage />
    <ArticlesOfIncorporation />
    <ApplicationForm />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import OverviewOfInternationalInsurance from "./internationaIinsurances.vue";
import InsuranceAndBrokerage from "../brokerageCompanies/insuranceAndBrokerages.vue";
import ArticlesOfIncorporation from "./articlesOfIncorporation.vue";
import ApplicationForm from "../brokerageCompanies/applicationForms.vue";

export default {
  components: {
    Exchange,
    OverviewOfInternationalInsurance,
    InsuranceAndBrokerage,
    ArticlesOfIncorporation,
    ApplicationForm,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
