<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__content">
        <div class="footer-nav">
          <div class="info-block">
            <ul class="margin-left-50">
              <li class="list mail-icon">info@crasadr.com</li>
              <li class="list">anylicense@crasadr.com</li>
            </ul>
          </div>
          <div class="info-block">
            <ul class="margin-left-50">
              <li class="list place-icon ">
                SRFRB, Tifariti, Sahrawi Arab Democratic <br>
                Republic, 2390835 Tifariti <br>
                Western Sahara, SADR
              </li>
            </ul>
          </div>
          <div class="info-block">
            <ul>
              <p class="list">
                For all questions contact to the licensed representative –
                <a href="https://advisorycorpws.com/" target="_blank" class="list">WS MANAGEMENT AND ADVISORY CORPORATION
                  LTD</a>
              </p>
            </ul>
          </div>
        </div>
        <div class="footer-line">
          <div class="privacy-policy-block">
            <p class="list">Disclaimer Privacy Policy</p>
            <p class="list">Copyright © 2023 Western Sahara, SADR . All Rights Reserved.</p>
          </div>
          <!--            <div class="mail-footer">-->
          <!--                <p class="block-input">-->
          <!--                    <el-form :inline="true" :model="formInline" class="demo-form-inline">-->
          <!--                    <el-form-item label="Approved by">-->
          <!--                        <el-input v-model="formInline.user" placeholder="Your e-mail"></el-input>-->
          <!--                    </el-form-item>-->
          <!--                        <el-form-item>-->
          <!--                        <el-button type="primary" @click="onSubmit">GO!</el-button>-->
          <!--                    </el-form-item>-->
          <!--                    </el-form>-->
          <!--                </p>-->
          <!--            </div>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  background: #006942;
  padding: 50px;
}

.footer__wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.footer-nav {
  display: flex;
  justify-content: space-around;
  @media (max-width: 860px) {
    flex-direction: column;
  }
}

ul {
  margin: 0;
  padding: 0;
  @media (max-width: 860px) {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
  }

  li {
    list-style-type: none;
  }
}

.info-block {
  width: 50%;
  @media (max-width: 1180px) {
    width: 40%;
  }
  @media (max-width: 860px) {
    width: 100%;
  }

  &:last-of-type {
    width: 45%;
    @media (max-width: 1180px) {
      width: 40%;
      @media (max-width: 860px) {
        width: 100%;
      }
    }
  }

  span {
    display: block;
  }
}

.list {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #fbfbfb;
  cursor: pointer;
  margin: 0;
  @media (max-width: 945px) {
    font-size: 14px;
  }
  @media (max-width: 860px) {
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    font-size: 10px;
    line-height: 14px;
  }
}

.list a {
  color: #fbfbfb;
}

.mail-icon {
  position: relative;

  &::before {
    content: "";
    background: url("../assets/footer/email.svg");
    position: absolute;
    left: -40px;
    width: 30px;
    height: 25px;
  }
}

.place-icon {
  position: relative;

  &::before {
    content: "";
    background: url("../assets/footer/place.svg");
    position: absolute;
    top: 0;
    left: -40px;
    width: 30px;
    height: 25px;
  }
}

.privacy-policy-block {
  display: flex;
  flex-direction: column-reverse;

  p {
    @media (max-width: 860px) {
      justify-content: center;
      text-align: center;
    }
  }
}

.block-input {
  @media (max-width: 860px) {
    margin-bottom: 20px;
  }
}

.margin-left-50 {
  margin-left: 50px;
  @media (max-width: 480px) {
    margin-left: 0px;
  }
}

.footer-line {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    padding-top: 0px;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.demo-form-inline {
  display: flex;
}

.el-button {
  padding: 11px 48px;
  font-size: 16px;
  font-family: "NotoSans-Regular";
  @media (max-width: 945px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }
}

.el-input {
  width: 245px;
}

.el-button--primary {
  background-color: #2d2d2d;
  border-color: #2d2d2d;
}

@media (max-width: 480px) {
  .demo-form-inline {
    display: flex;
    flex-direction: column;
  }
}
</style>
