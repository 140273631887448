<template>
  <div class="main-wrapper">
    <Exchange />
    <InternationalTrustCorporations />
    <TrustLicense />
    <TrustCorporation />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import InternationalTrustCorporations from "./internationalTrustCorporations";
import TrustLicense from "./trustLicense";
import TrustCorporation from "./trustCorporation";

export default {
  components: {
    Exchange,
    InternationalTrustCorporations,
    TrustLicense,
    TrustCorporation,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
