<template>
  <div class="applyingBusinesName">
    <div class="applyingBusinesName-wrapper">
      <div class="applyingBusinesName-content">
        <div>
          <h2>APPLYING FOR A BUSINESS NAME</h2>
          <div class="block">
            <p class="text">
              There are two main ways to register a business name:
            </p>
            <div>
              <span class="text"
                >Reserve a business name and then apply for the name at a later
                stage, or
              </span>
              <span class="text">Apply for the name directly </span>
              <p class="subtitle">Reserving a business name</p>
              <p class="text">
                You are able to put a business name on hold for six months ahead
                of applying to register the business name. After this time, you
                must either give up the name or reserve the name again. However,
                you should note that you may only reserve a proposed business
                name twice over a five-year period.
              </p>
              <p class="text">
                Reservation is easily done online. The fee for reserving a
                business name is EUR 150.
              </p>
              <p class="subtitle">Registering a business name</p>
              <p class="text">
                The fee for registering a business name is included in the cost
                of the application fee. It is quicker and cheaper for you to
                apply for the business name directly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.applyingBusinesName-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 240px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
      padding-bottom: 35px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  margin: 0;
  padding-top: 85px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
}
.subtitle {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #006942;
  margin-top: 22px;
  margin-bottom: 22px;
  @media (max-width: 740px) {
    font-size: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-skils {
  display: flex;
}
ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 56px;
  &:first-child {
    margin-right: 200px;
  }
  li {
    list-style-type: none;
    margin-left: 30px;
    margin-bottom: 10px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: 6px;
      left: -23px;
      background: url("../../../assets/brokerageCompanies/ellipse.svg");
    }
  }
}
.applyingBusinesName-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
span {
  display: block;
  padding-bottom: 16px;
  margin-left: 40px;
  position: relative;
  &::before {
    content: "";
    width: 20px;
    height: 2px;
    background: #006942;
    position: absolute;
    top: 11px;
    left: -38px;
  }
}
</style>
