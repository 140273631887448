<template>
  <div class="resolutionAim-info">
    <div class="resolutionAim-info-wrapper">
      <div class="resolutionAim-info-content">
        <div style="width: 80%">
          <div class="block">
            <h2>RESOLUTION ACTION AIM TO:</h2>
            <div class="block-indent">
              <ul class="text">
                <li>Ensure continuity of the bank’s critical functions</li>
                <li>Safeguard public interests and financial stability</li>
                <li>Minimize costs to taxpayers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.resolutionAim-info-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    padding-top: 35px;
    padding-top: 0px;
    max-width: 1024px;
  }
}
.block {
  background: #af8e6f;
  padding: 100px 0px 100px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1100px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-indent {
  padding-right: 200px;
  margin-top: 50px;
  @media (max-width: 740px) {
    padding-right: 20px;
    margin-top: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  width: 65%;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
    width: 100%;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.resolutionAim-info-content {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  p {
    padding-bottom: 20px;
  }
}
</style>
