<template>
  <div class="proposed-autonomy">
    <div class="proposed-autonomy-wrapper">
      <div class="proposed-autonomy-content">
        <div>
          <div class="block">
            <div class="block-green">
              <p>
                link the final objective of the macro-prudential policy with the
                intermediate objectives and the macro-prudential instruments;
              </p>
            </div>
            <div class="block-green">
              <p>
                establish a core framework for the implementation of
                macro-prudential instruments, including the indicators that
                follow the performance of systemic risk, to orient decisions
                related to the implementation, deactivation, or calibration of
                the macro-prudential instruments over time;
              </p>
            </div>
            <div class="block-green">
              <p>
                explain the decision-making at the Central Reserve Authority of
                Western Sahara, inter-institutional and public communication
                processes in the Central Reserve Authority of Western Sahara on
                the macro-prudential policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.proposed-autonomy-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 120px;
  @media (max-width: 1100px) {
    padding-top: 0px;
    max-width: 1024px;
    padding-bottom: 35px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
    @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  &-green {
    background: #006942;
    margin-right: 20px;
    width: 33%;
    @media (max-width: 1100px) {
      width: 100%;
      margin-bottom: 15px;
    }
    p:first-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      color: #fbfbfb;
      margin: 24px 48px 0px 48px;
      @media (max-width: 1100px) {
        margin: 20px 20px 20px 20px;
      }
    }
    p:last-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #fbfbfb;
      padding-bottom: 44px;
    }
  }
}
</style>
