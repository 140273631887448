<template>
  <div class="main-wrapper">
    <Exchange />
    <OurVision />
    <RegulatoryAuthority />
    <RegulatoryLaws />
  </div>
</template>

<script>
import Exchange from '../../exchange-rates.vue';
import OurVision from './ourVisions.vue';
import RegulatoryAuthority from './regulatoryAuthoritys.vue';
import RegulatoryLaws from './regulatoryLaw.vue';

export default {
  components: {
    Exchange,
    OurVision,
    RegulatoryAuthority,
    RegulatoryLaws,
  },
};
</script>

<style lang="scss" scoped>
    .main-wrapper {
        padding-top: 105px;
    }
</style>
