<template>
  <div class="main-wrapper">
    <Exchange />
    <ApplicationFormText />
    <ApplicationForm />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import ApplicationFormText from "./applicationForm.vue";
import ApplicationForm from "../brokerageCompanies/applicationForms.vue";

export default {
  components: {
    Exchange,
    ApplicationFormText,
    ApplicationForm,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
