<template>
  <div class="applying">
    <div class="applying-wrapper">
      <div class="applying-content">
        <div>
          <div class="block">
            <p class="text">
              In order to apply for a license please contact Western Sahara
              Management and Advisory Corporation
              <a href="https://wsadvisorycorp.com/" target="_blank"
                >(https://wsadvisorycorp.com/)</a
              >
              - the only certified agent of the Central Reserve Authority of
              Western Sahara, SADR.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.applying-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 150px;
  @media (max-width: 1100px) {
    padding-top: 0px;
    max-width: 1024px;
    padding-bottom: 50px;
  }
}
.block {
  background: #fff;
  padding: 50px 50px 50px 0px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
    padding-left: 0px;
    padding-bottom: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  padding-left: 200px;
  @media (max-width: 1100px) {
    padding-left: 0px;
  }
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-container {
  position: relative;
}

.applying-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
