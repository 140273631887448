<template>
  <div class="main-wrapper">
    <Exchange />
    <BusinessName />
    <BusinessNamesBaner />
    <ApplyingBusinessName />
    <HowGetBusinessName />
    <Step />
    <GettingReminder />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import BusinessName from "./businessName.vue";
import BusinessNamesBaner from "./businessNameBaner.vue";
import ApplyingBusinessName from "./applyingBusinesName.vue";
import HowGetBusinessName from "./howGetBusinesName.vue";
import Step from "./step.vue";
import GettingReminder from "./gettingReminders.vue";



export default {
  components: {
    Exchange,
    BusinessName,
    BusinessNamesBaner,
    ApplyingBusinessName,
    HowGetBusinessName,
    Step,
    GettingReminder,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
