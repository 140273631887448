<template>
  <div class="westernSahara-info">
    <div class="westernSahara-info-wrapper">
      <div class="westernSahara-info-content">
        <div>
          <div class="block">
            <h2>THE REGULATORY AUTHORITY - STRUCTURE AND FUNCTION</h2>
            <div class="block-indent">
              <p class="text">
                The Central Reserve Authority of Western Sahara was created to
                institute a new system to manage, direct control, and supervise
                the international financial services industry and domestic
                non-bank institutions in Western Sahara.
              </p>
              <p class="text">
                The Central Reserve Authority of Western Sahara was founded in
                2017 to control the range of activities to be regulated by the
                CRA of Western Sahara to include businesses operated or carried
                on under the Financial Institution Non-Banking Act, the
                Cooperative Societies Act, the Insurance Act. In addition to the
                above, this brought Western Sahara in line with current
                international trends toward consolidating the regulation and
                supervision of financial services under a single regulatory
                body.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.westernSahara-info-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  // height: 500px;
  @media (max-width: 1100px) {
    padding-top: 35px;
  }
  @media (max-width: 1024px) {
    padding-top: 0px;
  }
}
.block {
  background: #af8e6f;
  padding: 50px 0px 50px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1200px) {
    padding: 50px 0px 50px 200px;
  }
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-indent {
  padding-right: 200px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  width: 65%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.westernSahara-info-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.westernSahara-info-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
</style>
