<template>
  <div class="institutional-framework">
    <div class="institutional-framework-wrapper">
      <div class="institutional-framework-content">
        <div>
          <h2>INSTITUTIONAL FRAMEWORK</h2>
          <div class="block">
            <p class="text">
              At the institutional level, the Central Reserve Authority of
              Western Sahara, in accordance with the legal hierarchy of the
              objectives regarding the policies and the functions it implements,
              ensures their maximal synergy. For this purpose, the organization
              of the internal institutional structure supports the definition of
              responsibilities at each stage of drafting and implementing
              policies and promotes the timely exchange of information and
              critical assessments. As a result of this process, the final
              decision-making of the Central Reserve Authority of Western Sahara
              is expected to be well informed and to reflect the contribution by
              the relevant policies.
            </p>
            <p class="text">
              <span
                >The Central Reserve Authority of Western Sahara’s
                structures</span
              >
              that are more directly and sequentially involved in the
              implementation of the macro-prudential policy are:
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.institutional-framework-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  background: #fff;
  padding: 50px 50px 50px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    padding-bottom: 20px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  span {
    font-weight: bold;
  }
}
.block-img {
  position: absolute;
  right: 200px;
}
.institutional-framework-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
