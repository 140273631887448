<template>
  <div class="westernSahara-info">
    <div class="westernSahara-info-wrapper">
      <div class="westernSahara-info-content">
        <div>
          <div class="block">
            <h2>WHAT IS FINANCIAL STABILITY AND WHAT THREATENS IT?</h2>
            <div class="block-indent">
              <p class="text-bold">
                An economy develops under the conditions of financial stability,
                when:
              </p>
              <ul class="text">
                <li>
                  the financial system performs its financial intermediation and
                  risk management functions, thus aiding the clients and
                  economic entities to conduct financial transactions related to
                  saving, investment, borrowing, payments, and risk management.
                </li>
                <li>
                  financial institutions are prepared to cope with shocks of
                  different sizes and forms.
                </li>
              </ul>
              <p class="text-bold">Financial stability may be affected by:</p>
              <ul class="text">
                <li>
                  internal weaknesses of one or several institutions of the
                  financial system,
                </li>
                <li>
                  shocks coming from interaction with the external economic
                  environment,
                </li>
                <li>the combined effect of these two factors.</li>
              </ul>
              <p class="text-bold">
                The shocks from interaction with the external environment may be
                caused by:
              </p>
              <ul class="text">
                <li>
                  changes in macroeconomic policies or in the institutional
                  framework,
                </li>
                <li>
                  a pronounced fluctuation in capital flows, particularly in
                  those of the portfolio, caused by changes in the financial and
                  economic cycle with partner countries.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.westernSahara-info-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 850px) {
    padding-top: 35px;
    max-width: 1024px;
    justify-content: center;
  }
}
.block {
  background: #af8e6f;
  padding: 50px 0px 50px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1100px) {
    padding: 50px 0px 50px 100px;
  }
  @media (max-width: 850px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-indent {
  padding-right: 200px;
  @media (max-width: 1100px) {
    padding-right: 100px;
  }
  @media (max-width: 850px) {
    padding-right: 0px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  width: 65%;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
    line-height: 30px;
    width: 88%;
    margin-bottom: 10px;
  }
}
.text-bold {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.westernSahara-info-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 30px;
  li {
    margin-bottom: 10px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: -28px;
      width: 10px;
      height: 10px;
      background: #ffffff;
      border-radius: 50%;
    }
  }
}
</style>
