<template>
  <div class="main-wrapper">
    <Exchange />
    <MacroPrudentialPolicies />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import MacroPrudentialPolicies from "./macroPrudentialPolicies";


export default {
  components: {
    Exchange,
    MacroPrudentialPolicies,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
