<template>
  <div class="bankingLicenses">
    <div class="bankingLicenses-wrapper">
      <div class="bankingLicenses-content">
        <div class="sectionOne">
          <div class="block-green">
            <h2 class="title-white">Class I International Banking License</h2>
            <p class="text-white">
              A licensed institution holding a Class I International Banking
              License shall maintain a minimum capital as may be determined by
              the CRA of Western Sahara from time to time, but not being less
              than three million United States dollars in paid-up capital, of
              which five hundred thousand United States dollars shall be
              deposited with the CRA of Western Sahara or in a manner approved
              by the CRA of Western Sahara on the recommendation of the
              appropriate official, as the case may be.
            </p>
          </div>
          <div class="block-grey">
            <h2 class="title-black">Class II International Banking License</h2>
            <p class="text-black">
              A Class II International Banking License permits the licensed
              institution to carry on international banking business for a
              restricted list of customers, approved by the CRA of Western
              Sahara from time to time, or specified in the list issued as part
              of the terms upon which the license is granted. A licensed
              institution holding a Class II International Banking License shall
              maintain a minimum capital as may be determined by the CRA of
              Western Sahara from time to time, but not being less than five
              hundred thousand United States dollars in paid-up capital, of
              which one hundred thousand United States dollars shall be
              deposited with the CRA of Western Sahara or in a manner approved
              by the CRA of Western Sahara on the recommendation of the
              appropriate official, as the case may be.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.bankingLicenses-wrapper {
  margin: 0 auto;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1150px) {
    max-width: 1024px;
  }
}
.sectionOne {
  width: 100%;
  display: flex;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
}
.block-green {
  background: #006942;
  padding: 48px 79px 48px 79px;
  margin-left: 200px;
  position: relative;
  margin-bottom: 20px;
  z-index: 222;
  height: 350px;
  @media (max-width: 1350px) {
    margin-left: 100px;
  }
  @media (max-width: 1250px) {
    margin-left: 0px;
  }
  @media (max-width: 1150px) {
    height: auto;
  }
  @media (max-width: 850px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-grey {
  background: #e7e7e7;
  padding: 48px 100px 47px 79px;
  position: relative;
  z-index: 222;
  margin-left: 23px;
  height: 350px;
  @media (max-width: 1150px) {
    height: auto;
    margin-left: 0;
  }
  @media (max-width: 850px) {
    padding: 20px 20px 20px 20px;
  }
}
.title-white {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
    line-height: 25px;
  }
}
.title-black {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #252827;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
    line-height: 25px;
  }
}
.text-white {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.text-black {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-img {
  position: absolute;
  right: 200px;
}
.bankingLicenses-content {
  p {
    padding-bottom: 20px;
  }
}
.bankingLicenses-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
</style>
