<template>
  <div class="trustLicense">
    <div class="trustLicense-wrapper">
      <div class="trustLicense-content">
        <div class="sectionOne">
          <div class="block-grey">
            <h2 class="title-black">
              Class I General International Trust License
            </h2>
            <p class="text-black">
              A general international trust license permits carrying on of
              international trust business without restrictions. A licensed
              institution holding a Class I General International Trust License
              shall maintain a minimum capital as may be determined by the CRA
              of Western Sahara from time to time, but not being less than two
              hundred and fifty thousand United States dollars in paid-up
              capital.
            </p>
          </div>
          <div class="block-green">
            <h2 class="title-white">
              Class II Restricted International Trust License
            </h2>
            <p class="text-white">
              A Class II Restricted International Trust License the licensed
              institution to carry on international trust business for a
              restricted list of customers, approved by the CRA of Western
              Sahara from time to time, or specified in the list issued as part
              of the terms upon which the license is granted.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.trustLicense-wrapper {
  margin: 0 auto;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    max-width: 1024px;
  }
}
.sectionOne {
  width: 100%;
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
}
.block-green {
  background: #006942;
  padding: 48px 79px 48px 79px;
  margin-right: 200px;
  position: relative;
  margin-bottom: 20px;
  z-index: 222;
  height: 280px;
  @media (max-width: 1230px) {
    margin-right: 100px;
  }
  @media (max-width: 1100px) {
    height: auto;
    padding: 20px 20px 20px 20px;
    margin-right: 0;
  }
}
.block-grey {
  background: #e7e7e7;
  padding: 48px 79px 47px 200px;
  position: relative;
  z-index: 222;
  margin-right: 23px;
  height: 280px;
  @media (max-width: 1230px) {
    padding: 48px 79px 47px 100px;
  }
  @media (max-width: 1100px) {
    height: auto;
    padding: 20px 20px 20px 20px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.title-white {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
  }
}
.title-black {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #252827;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
  }
}
.text-white {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.text-black {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-img {
  position: absolute;
  right: 200px;
}
.trustLicense-content {
  p {
    padding-bottom: 20px;
  }
}
</style>
