<template>
  <div class="documents">
    <div class="documents-wrapper">
      <div class="documents-content">
        <div>
          <div class="block">
            <h2>BANKING LICENSE REQUIRED DOCUMENTS</h2>
            <div class="block-indent">
              <p class="text">
                An applicant for a banking license must submit the following
                information for assessment by the CRA of SADR. The guidelines
                for the issue of offshore bank license can be summarized as
                follows:
              </p>
              <ul class="text">
                <li>
                  Details of ownership, financial capacity, and financial
                  history
                </li>
                <li>
                  Information on major Shareholders and the character and
                  experience of Senior Management and Board of Directors
                </li>
                <li>Details of the applicant's capital structure</li>
                <li>
                  A detailed Business Plan outlining the types of services to be
                  offered including three-year projections for assets,
                  liabilities, and profitability
                </li>
                <li>
                  Certificate of no criminal record to ensure that there are no
                  criminal records about Authorized Person, Additional
                  Authorized Persons (if any), all Directors/Officers,
                  Shareholders and Beneficial Owners, not older than 3 months
                </li>
                <li>Utility bill of all Shareholders and Directors</li>
                <li>Banking Reference of all Shareholders and Directors</li>
              </ul>
              <div class="download-block">
                <a download="Application_form.pdf" href="Application_form.pdf">
                  <span class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    Application Form
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.documents {
  background: url("../../../assets/document/documentBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.documents-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  // height: 500px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
    justify-content: center;
  }
}
.block {
  background: #af8e6f;
  padding: 90px 128px 90px 128px;
  position: relative;
  margin-right: 200px;
  position: relative;
  top: -260px;
  z-index: 222;
  width: 630px;
  @media (max-width: 1100px) {
    margin-right: 0;
    top: 0;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: auto;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 56px;
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
ul {
  margin: 0;
  padding: 0;
  margin-bottom: 46px;
  li {
    list-style-type: none;
    margin-bottom: 16px;
    margin-left: 40px;
    position: relative;
    &::before {
      content: "";
      width: 20px;
      height: 2px;
      background: #fbfbfb;
      position: absolute;
      top: 12px;
      left: -37px;
    }
  }
}
.documents-content {
  p {
    padding-bottom: 20px;
  }
}
.documents-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-green {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.download-block {
  background: #ffffff;
  position: relative;
  left: -127px;
  width: 45%;
  padding: 19px 20px 19px 125px;
  @media (max-width: 1100px) {
    padding: 20px 20px 20px 20px;
    left: 0;
  }
  @media (max-width: 480px) {
    width: 88%;
  }
  span {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
}
a {
  text-decoration: none;
}
</style>
