<template>
  <div class="main-wrapper">
    <Exchange />
    <InstitutionalFramework />
    <Financ />
  </div>
</template>

<script>
import Exchange from '../../exchange-rates.vue';
import InstitutionalFramework from './institutionalFramework.vue';
import Financ from './financ.vue';

export default {
  components: {
    Exchange,
    InstitutionalFramework,
    Financ,
  },
};
</script>

<style lang="scss" scoped>
    .main-wrapper {
        padding-top: 105px;
    }
</style>
