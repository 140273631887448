<template>
  <div class="countrys-info">
    <div class="countrys-info-wrapper">
      <div class="countrys-info-content">
        <div>
          <h2>COUNTRY’S INFO</h2>
          <div class="block-img-two">
            <img src="../../assets/countrysInfo/img1.jpg" alt=""/>
          </div>
          <div class="block">
            <p class="text">
              The Central Reserve Authority (CRA), established in May 2016, diligently oversees the financial services
              sector in the region. Operating from the internationally recognized Free Zone, the CRA ensures adherence
              to the highest global standards in financial regulation and supervision, under the governance of the local
              administrative authority.
            </p>
            <p class="text">
              This region, while having its own government, legislature, and comprehensive company laws, functions with
              significant autonomy. Recognized as a burgeoning hub among the world’s premier offshore finance centers,
              it excels particularly in e-commerce, distinguishing itself as a center of excellence.
            </p>
            <p v-if="isLearnMode" class="text">
              The Central Reserve Authority plays a pivotal role as a statutory body, ensuring that the financial
              industry here operates within a robust regulatory framework. This includes modern banking, insurance, and
              investment laws, facilitating a secure and progressive business environment.
            </p>
            <p v-if="isLearnMode" class="text">
              For those interested in engaging with our financial services, the CRA offers detailed information on
              licensing procedures and the robust protections our licenses afford. These details are accessible through
              various menu options on our official site.
            </p>
            <p v-if="isLearnMode" class="text">
              The CRA's regulatory purview extends beyond financial entities to include non-financial service providers
              such as law firms, real estate agents, car dealerships, and vendors of high-value items like luxury goods
              and jewelry. It also oversees Non-Profit Organizations, ensuring compliance with stringent anti-money
              laundering protocols and legislation aimed at countering the financing of terrorism.
            </p>
            <div v-if="!isLearnMode" class="button-container">
              <el-button @click="isLearnMode = true" round
              >READ MORE
                <svg
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L4.5 4L8 1" stroke="#006942" stroke-width="2"/>
                </svg>
              </el-button>
            </div>
          </div>
        </div>
        <div class="block-img">
          <img src="../../assets/countrysInfo/img1.jpg" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.countrys-info {
  @media (max-width: 1110px) {
    background: #fff;
  }
}

.countrys-info-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1110px) {
    max-width: 1024px;
  }
  @media (max-width: 850px) {
    padding-top: 35px;
  }
  @media (max-width: 850px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.block {
  background: #fff;
  padding: 50px 50px 50px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1110px) {
    padding: 20px 20px 20px 20px;
  }
}

h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }
  @media (max-width: 320px) {
    font-size: 18px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -85%;
    @media (max-width: 1110px) {
      display: none;
    }
  }
}

.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.block-img {
  position: absolute;
  right: 200px;
  @media (max-width: 1260px) {
    right: 50px;
  }
  @media (max-width: 1100px) {
    display: none;
  }
}

.block-img-two {
  display: none;
  text-align: center;
  @media (max-width: 1100px) {
    display: block;
  }

  img {
    @media (max-width: 740px) {
      width: 70%;
    }
  }
}

.countrys-info-content {
  display: flex;
  width: 60%;
  @media (max-width: 1110px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  p {
    padding-bottom: 20px;
  }
}

.countrys-info-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}

.el-button {
  font-family: "NotoSans-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border: none;
  text-transform: uppercase;
  color: #006942;
  transition-duration: 1s;
  @media (max-width: 740px) {
    font-size: 12px;
  }
}

.el-button.is-round {
  border-radius: 0px;
  padding: 12px 20px 12px 0px;
  @media (max-width: 740px) {
    padding: 0px 0px 0px 0px;
  }
}

.el-button:focus,
.el-button:hover {
  color: #409eff;
  border-color: transparent;
  background-color: transparent;
}

svg {
  padding-bottom: 2px;
}
</style>
