<template>
  <div class="westernSahara-info">
    <div class="westernSahara-info-wrapper">
      <div class="westernSahara-info-content">
        <div>
          <div class="block">
            <h2>WHAT IS WESTERN SAHARA?</h2>
            <div class="block-indent">
              <p class="text">
                Western Sahara is a territory with a unique status, featuring an area known as the Free Zone. This zone
                was proclaimed by the Polisario Front on February 27, 1976, and is administered independently from the
                rest of the territory. The temporary capital is Tifariti, moved there in 2008.
              </p>
              <p class="text">
                Western Sahara is recognized as a full member of the African Union. In 1999, the region adopted a new
                Constitution, shaping a governmental structure similar to the parliamentary systems found in many
                European
                countries.
              </p>
              <p class="text">
                The highest political office in Western Sahara is the President. Brahim Ghali, born on September 16,
                1948, currently holds this position. He was elected on July 9, 2016, during elections held in the
                Sahrawi refugee camps in Tindouf, Algeria. President Ghali succeeded
                <span v-if="isLearnMode">Mohamed Abdelaziz following his death on May 31, 2016.</span>
                <span v-else>...</span>
              </p>
              <p v-if="isLearnMode" class="text">
                The governmental framework includes a Council of Ministers led by the Prime Minister, a judicial branch
                with judges appointed by the President, and a legislative body known as the Sahrawi National Council.
                The judiciary comprises trial courts, appeals courts, and a supreme court, ensuring the administration
                of justice throughout the Free Zone. The government of Western Sahara asserts sovereignty over the
                entire territory, although its administrative control is primarily within the Free Zone.
              </p>
              <div v-if="!isLearnMode" class="button-container">
                <el-button @click="isLearnMode = true" round
                >READ MORE
                  <svg
                      width="9"
                      height="6"
                      viewBox="0 0 9 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L4.5 4L8 1" stroke="#fff" stroke-width="2"/>
                  </svg>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.westernSahara-info-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  // height: 500px;
  @media (max-width: 1110px) {
    max-width: 1024px;
  }
  @media (max-width: 850px) {
    padding-top: 35px;
  }
}

.block {
  background: #af8e6f;
  padding: 50px 0px 50px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1110px) {
    padding: 20px 20px 20px 20px;
  }
}

.block-indent {
  padding-right: 200px;
  @media (max-width: 1110px) {
    padding-right: 0px;
  }
}

h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  @media (max-width: 740px) {
    font-size: 24px;
  }
  @media (max-width: 320px) {
    font-size: 18px;
  }

  &::before {
    content: "";
    width: 55%;
    height: 1px;
    background: #fbfbfb;
    position: absolute;
    right: 0;
    @media (max-width: 1480px) {
      width: 30%;
    }
    @media (max-width: 1110px) {
      display: none;
    }
  }
}

.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.westernSahara-info-content {
  display: flex;
  width: 80%;
  @media (max-width: 1110px) {
    width: 100%;
  }

  p {
    padding-bottom: 20px;
  }
}

.westernSahara-info-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}

.el-button {
  font-family: "NotoSans-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border: none;
  text-transform: uppercase;
  background: transparent;
  color: #fbfbfb;
  transition-duration: 1s;
  @media (max-width: 740px) {
    font-size: 12px;
  }
}

.el-button.is-round {
  border-radius: 0px;
  padding: 12px 20px 12px 0px;
}

.el-button:focus,
.el-button:hover {
  color: #409eff;
  border-color: transparent;
  background-color: transparent;
}

svg {
  padding-bottom: 2px;
}
</style>
