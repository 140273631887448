<template>
  <div class="insuranceAndBrokerages">
    <div class="insuranceAndBrokerages-wrapper">
      <div class="insuranceAndBrokerages-content"></div>
      <div class="countrys-info">
        <div class="countrys-info-wrapper">
          <div class="countrys-info-content">
            <div>
              <div class="blockTwo">

                <a
                  download="The-Insurance-and-Brokerage-act-2017.pdf"
                  href="The-Insurance-and-Brokerage-act-2017.pdf"
                >
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    The Insurance and brokerage Act 2017
                  </p>
                </a>

                <a
                    download="Companies_Act_2018.pdf"
                    href="Companies_Act_2018.pdf"
                >
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    Companies Act 2018
                  </p>
                </a>

                <a
                    download="Opening_an_account_with_us.pdf"
                    href="Opening_an_account_with_us.pdf"
                >
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    Opening an account with us
                  </p>
                </a>

                <a
                    download="WSADR_Conditions.pdf"
                    href="WSADR_Conditions.pdf"
                >
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    WSADR Conditions
                  </p>
                </a>

                <a
                    download="Application_form.pdf"
                    href="Application_form.pdf"
                >
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    Application form
                  </p>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.insuranceAndBrokerages-wrapper {
  margin: 0 auto;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    max-width: 1024px;
  }
}
.blockTwo {
  background: #fff;
  padding: 20px 20px 20px 200px;
  position: relative;
  z-index: 222;
  width: 30%;
  @media (max-width: 1100px) {
    width: 50%;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: 88%;
  }
}

.text-green {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}
.insuranceAndBrokerages-content {
  display: flex;
  width: 80%;
  p {
    padding-bottom: 20px;
  }
}
.insuranceAndBrokerages-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
a {
  text-decoration: none;
}
</style>
