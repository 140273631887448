<template>
  <div class="politic">
    <div class="politic-wrapper">
      <div class="politic-content">
        <div>
          <h2>
            POLITICS OF <br />
            THE WESTERN SAHARA
          </h2>
          <div class="block">
            <p class="text">
              The office of the President is the highest office in country. The
              president is responsible for appointing the prime minister or
              premier. Brahim Ghali is the incumbent president while Abdelkader
              Taleb is the premier. The government consists of a council of
              minister led by the prime minister, a judiciary branch whose
              members are appointed by the president, and the parliament, which
              is officially titled the Sahrawi National Council. Since its
              establishment in 1976, the government has evolved from an ad hoc
              structure to a functional government. The ministries are headed by
              ministers and are fully functional. The judiciary is well
              established with trial courts, the court of appeal and the Supreme
              Court. Even though it is not recognized as a democratic state, the
              country has banned the death penalty. In 1999, Western Sahara
              adopted a constitution similar to European constitutions. The
              constitution recognizes the Sahrawis as Africans, Arabs, and
              Muslim people.
            </p>
          </div>
        </div>
        <div class="block-container">
          <div class="block-img">
            <img src="../../assets/politics/parliament.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.politic {
  @media (max-width: 1100px) {
    background: #fff;
  }
}
.politic-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1100px) {
    max-width: 1024px;
  }
  @media (max-width: 740px) {
    padding-top: 35px;
  }
}
.block {
  background: #fff;
  padding: 50px 50px 50px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1200px) {
    padding: 50px 50px 50px 100px;
  }
  @media (max-width: 850px) {
    padding: 0px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1200px) {
    padding-left: 100px;
  }
  @media (max-width: 850px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -85%;
    @media (max-width: 1100px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-container {
  position: relative;
}
.block-img {
  position: absolute;
  top: 200px;
  left: 100px;
  @media (max-width: 1100px) {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
  }
  @media (max-width: 520px) {
    display: none;
  }
}
.politic-content {
  display: flex;
  width: 44%;
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
