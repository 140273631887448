<template>
  <div class="internationalGamblingLicense">
    <div class="internationalGamblingLicense-wrapper">
      <div class="internationalGamblingLicense-content">
        <div>
          <h2>INTERNATIONAL GAMBLING LICENSE</h2>
          <div class="block">
            <p class="text">
              A Gambling License is a certificate by the CRA of Western Sahara.
              Gambling licenses are usually required by organizations that offer
              any type of games of chance or those that involve wagering, such
              as roulette, poker, slots, pari-mutuel racing, and lotteries,
              among a few.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="blockTwo">

      <a style="text-decoration: none;" download="The-Banking-and-Trust-Act-2017.pdf" href="The-Banking-and-Trust-Act-2017.pdf">
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          The Banking and Trust Act 2017
        </p>
      </a>

      <a style="text-decoration: none;"
         download="Companies_Act_2018.pdf"
         href="Companies_Act_2018.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Companies Act 2018
        </p>
      </a>

      <a style="text-decoration: none;"
         download="Opening_an_account_with_us.pdf"
         href="Opening_an_account_with_us.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Opening an account with us
        </p>
      </a>

      <a style="text-decoration: none;"
         download="WSADR_Conditions.pdf"
         href="WSADR_Conditions.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          WSADR Conditions
        </p>
      </a>

      <a style="text-decoration: none;"
         download="Application_form.pdf"
         href="Application_form.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Application form
        </p>
      </a>

    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.internationalGamblingLicense-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    padding-bottom: 0px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  margin: 0;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}
.internationalGamblingLicense-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}

.blockTwo {
  background: #fff;
  padding: 20px 20px 20px 200px;
  position: relative;
  z-index: 222;
  width: 30%;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    width: 50%;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: 88%;
  }
}
.text-green {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}

</style>
