<template>
  <div class="main-wrapper">
    <Exchange />
    <PaymentSystem />
    <BlockWithPicture />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import PaymentSystem from "./paymentSystem";
import BlockWithPicture from "./blockWithPicture";

export default {
  components: {
    Exchange,
    PaymentSystem,
    BlockWithPicture,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
