<template>
  <div class="govermentSecurities">
    <div class="govermentSecurities-wrapper">
      <div class="govermentSecurities-content">
        <div>
          <h2>GOVERNMENT SECURITIES</h2>
          <div class="block">
            <p class="text">
              The government securities are debt instruments issued and
              guaranteed by the Government of Western Sahara. Government
              securities are issued in the form of Treasury bills and bonds.
            </p>
            <p class="text">
              Treasury bills are short-term financial instruments with 3, 6 and
              12 month maturity. The yield from this instrument is calculated as
              the difference between the nominal value and the acquisition price
              of the coupon.
            </p>
            <p class="text">
              Bonds are medium-term and long-term financial instruments with 2,
              3, 5, 7 and 10-year maturity. The yield, in the form of a coupon,
              is paid every 6 months during the lifetime of the instrument.
              Generally, bonds are issued with a fixed coupon, i.e. with a fixed
              interest rate throughout the lifetime of the instrument. But, they
              may be also issued with a variable coupon. In this case, the
              interest rate is variable and is defined every year as the sum of
              the variable index rate and a fixed margin that is defined in the
              auction.
            </p>
            <p class="text">
              The index rate is calculated before the auction date
              re-determining the variable bond coupon, as the weighted average
              of the 12 month Treasury bills of the last three auctions.
            </p>
            <p class="text">
              In the capacity of the Government’s fiscal agent, the CRA of
              Western Sahara is responsible for the organization of the
              auctions, settlement, and keeping of the electronic registry of
              government securities issued in the domestic market.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.govermentSecurities-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}

.no-line {
  &::before {
    display: none;
  }
}
.govermentSecurities-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
