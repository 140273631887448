<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">
            <h1>FEES</h1>
            <div class="form-block">
              <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                style="display: flex"
              >
                <el-form-item label="Approved by">
                  <el-input
                    v-model="formInline.user"
                    placeholder="Company Search"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">Search</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay h1 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  text-transform: uppercase;
  color: #fafafa;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 29px;
  }
}
.blur__block {
  width: 100%;
  height: 100%;
  left: 375px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    width: auto;
  }
  @media (max-width: 1350px) {
    height: 64%;
  }
}

.fullscreen-bg {
  overflow: hidden;
  //   z-index: -100;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 36%;
  @media (max-width: 1600px) {
    padding-top: 31%;
  }
  @media (max-width: 1300px) {
    padding-top: 41%;
  }
  @media (max-width: 1024px) {
    padding-top: 48%;
  }
  @media (max-width: 780px) {
    padding-top: 60%;
  }
  @media (max-width: 740px) {
    padding-top: 68%;
  }
  @media (max-width: 560px) {
    padding-top: 80%;
  }
  @media (max-width: 480px) {
    padding-top: 100%;
  }
}

.overlay {
  background: url("../../../assets/servicesFees/servicesFeesBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  z-index: 4;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg__video {
    display: none;
  }

  .overlay h1 {
    font-size: 24px;
  }
}
.el-button--primary {
  color: #fff;
  background-color: #006942;
  border-color: #006942;
}
@media (max-width: 490px) {
  .el-button--primary {
    width: 100%;
    margin-top: 10px;
  }
  .demo-form-inline {
    flex-direction: column;
  }
}
</style>
