<template>
  <div class="businessName">
    <div class="businessName-wrapper">
      <div class="businessName-content">
        <div>
          <h2>WHAT IS A BUSINESS NAME AND DO I NEED ONE?</h2>
          <div class="block">
            <p class="text">
              Many businesses choose to trade under names that are different
              from the actual names of the business operators. Business names
              can be used by sole traders, partnerships, and companies. This
              section provides an overview of what business names are, how they
              can work for you, and how to register a business name.
            </p>
            <p class="text">
              A business name, sometimes known as a trading name, is a name
              under which a business operator conducts their business.
            </p>
            <p class="text">
              Any business operator that is trading under a name that is not
              their own must register a business name with the CRA unless they
              fall under an exception.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.businessName-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  margin: 0;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
}
.text-bold {
  font-weight: bold;
}
.businessName-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
