<template>
  <div class="main-wrapper">
    <Exchange />
    <InternationalBanking />
    <BankingLicense />
    <CraOfWestern />
    <Documents />
  </div>
</template>

<script>
import Exchange from '../../exchange-rates.vue';
import InternationalBanking from './internationalBanking.vue';
import BankingLicense from './bankingLicenses.vue';
import CraOfWestern from './craOfWester.vue';
import Documents from './document.vue';

export default {
  components: {
    Exchange,
    InternationalBanking,
    BankingLicense,
    CraOfWestern,
    Documents,
  },
};
</script>

<style lang="scss" scoped>
    .main-wrapper {
        padding-top: 105px;
    }
</style>
