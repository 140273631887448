<template>
  <div class="gallery-info">
    <div class="gallery-info-wrapper">
      <div class="gallery-info-content">
        <div>
          <div class="block">
            <div class="block-img">
              <img src="..\..\assets\gallerys\photo2.jpg" alt="" />
            </div>
            <div class="block-img">
              <img src="..\..\assets\gallerys\photo3.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.gallery-info-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  // height: 500px;
  @media (max-width: 1024px) {
    max-width: 1024px;
    justify-content: center;
  }
  @media (max-width: 850px) {
    padding-top: 35px;
  }
}
.block {
  display: flex;
  width: 90%;
  margin-left: auto;
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
  @media (max-width: 850px) {
    flex-direction: column;
  }
  &-img {
    display: flex;
    justify-content: flex-end;
    width: 595px;
    height: 440px;
    @media (max-width: 850px) {
      margin-bottom: 15px;
      width: 100%;
      height: 100%;
    }
    &:nth-child(-n + 2) {
      padding-right: 20px;
      @media (max-width: 850px) {
        padding-right: 0px;
      }
    }
    img {
      width: 100%;
    }
  }
}
.block-indent {
  padding-right: 200px;
}
</style>
