<template>
  <div class="applicationForms">
    <div class="applicationForms-wrapper">
      <div class="applicationForms-content"></div>
      <div class="countrys-info">
        <div class="countrys-info-wrapper">
          <div class="countrys-info-content">
            <div>
              <div class="blockTwo">

                <a download="The-Banking-and-Trust-Act-2017.pdf" href="The-Banking-and-Trust-Act-2017.pdf">
                  <p class="text-green">
                    <img src="..\..\..\assets\about\pdf.svg" alt="" />
                    The Banking and Trust Act 2017
                  </p>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.applicationForms {
  padding-bottom: 150px;
  @media (max-width: 1100px) {
    padding-bottom: 50px;
  }
}
.applicationForms-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  // height: 500px;
  @media (max-width: 1100px) {
    max-width: 1024px;
  }
}
.blockTwo {
  background: #fff;
  padding: 20px 20px 20px 200px;
  position: relative;
  z-index: 222;
  width: 30%;
  @media (max-width: 1100px) {
    width: 50%;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: 88%;
  }
}
.text-green {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}
.applicationForms-content {
  display: flex;
  width: 80%;
  p {
    padding-bottom: 20px;
  }
}
.applicationForms-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
a {
  text-decoration: none;
}
</style>
