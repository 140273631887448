<template>
  <div class="financial">
    <div class="financial-wrapper">
      <div class="financial-content">
        <div class="sectionOne">
          <div class="block-green">
            <h2 class="title-white">Financial Stability Department</h2>
            <p class="text-white">
              Monitors systemic risk indicators, develops the methodologies for
              its assessment and the operationalization of macro-prudential
              instruments; proposes the Financial Stability Advisory Committee
              the instrument and manner of implementation, monitors the impact
              of the instrument, prepares reports and analyses for the systemic
              risk performance, as well as assesses the stability of the
              financial system
            </p>
          </div>
          <div class="block-grey">
            <h2 class="title-black">The Governor</h2>
            <p class="text-black">
              Communicates the stance of the Central Reserve Authority of
              Western Sahara on systemic risks and the financial system
              stability, and decides on the implementation of the
              macro-prudential instrument if this is possible according to the
              decision-making procedures;
            </p>
          </div>
        </div>
        <div class="sectionTwo">
          <div class="block-greyTwo">
            <h2 class="title-black">Financial Stability Advisory Committee</h2>
            <p class="text-black">
              Assesses the performance of systemic risk, selects the
              macro-prudential instrument and determines its implementation;
              monitors the coordination of work for discussion within the
              institution and the preparation of legal and sub-legal acts
              necessary for the implementation of the instrument; formulates the
              macro-prudential stances for the Governor or the Supervisory
              Council in the framework of the Central Reserve Authority of
              Western Sahara’s role in safeguarding financial stability;
            </p>
          </div>
          <div class="block-white">
            <h2 class="title-green">Supervisory Council</h2>
            <p class="text-black">
              Reviews and approves the reports and analyses of the Central
              Reserve Authority of Western Sahara on the assessment of the
              financial stability situation; is informed on the assessments on
              the manner of implementation of the macro-prudential policy;
              approves sub-legal acts necessary for the implementation of the
              relevant instruments of macro-prudential policy.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="blockTwo">

      <a style="text-decoration: none"
         download="Certificate_of_incorporate_in_Arabic_for_WSADR.pdf"
         href="Certificate_of_incorporate_in_Arabic_for_WSADR.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Certificate of incorporate in Arabic for WSADR
        </p>
      </a>

      <a style="text-decoration: none"
         download="TRADE_CERTIFICATE.pdf"
         href="TRADE_CERTIFICATE.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Trade Certificate
        </p>
      </a>

      <a style="text-decoration: none"
         download="Ministry_of_Commerce.pdf"
         href="Ministry_of_Commerce.pdf"
      >
        <p class="text-green">
          <img src="..\..\..\assets\about\pdf.svg" alt="" />
          Ministry of Commerce
        </p>
      </a>

    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>

.blockTwo {
  background: #fff;
  padding: 20px 20px 20px 200px;
  position: relative;
  z-index: 222;
  width: 30%;
  @media (max-width: 740px) {
    width: 87%;
    padding: 20px 20px 20px 20px;
  }
}

.text-green {
  font-family: 'NotoSans-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}

.financial-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1165px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.sectionOne {
  width: 100%;
}
.sectionTwo {
  width: 100%;
  margin-left: auto;
}
.block-green {
  background: #006942;
  padding: 24px 79px 48px 200px;
  position: relative;
  margin-bottom: 20px;
  z-index: 222;
  height: 250px;
  @media (max-width: 1450px) {
    height: 300px;
  }
  @media (max-width: 1340px) {
    padding: 24px 50px 48px 200px;
  }
  @media (max-width: 1220px) {
    height: 350px;
  }
  @media (max-width: 1165px) {
    padding: 20px 20px 20px 20px;
    height: auto;
    margin-right: 0;
    margin-right: 0;
  }
}
.block-grey {
  background: #e7e7e7;
  padding: 24px 79px 47px 79px;
  position: relative;
  z-index: 222;
  margin-left: 150px;
  height: 250px;
  @media (max-width: 1340px) {
    padding: 24px 50px 47px 50px;
  }
  @media (max-width: 1165px) {
    padding: 20px 20px 20px 20px;
    height: auto;
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
  }
}
.block-greyTwo {
  background: #e7e7e7;
  padding: 24px 79px 48px 79px;
  position: relative;
  z-index: 222;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 150px;
  height: 250px;
  @media (max-width: 1450px) {
    height: 300px;
  }
  @media (max-width: 1340px) {
    padding: 24px 50px 48px 50px;
  }
  @media (max-width: 1220px) {
    height: 350px;
  }
  @media (max-width: 1165px) {
    padding: 20px 20px 20px 20px;
    height: auto;
    margin-right: 0;
    margin-left: 0;
  }
}
.block-white {
  background: #fff;
  padding: 24px 200px 48px 79px;
  position: relative;
  z-index: 222;
  margin-bottom: 20px;
  height: 250px;
  margin-left: 20px;
  @media (max-width: 1340px) {
    padding: 24px 200px 48px 50px;
  }
  @media (max-width: 1165px) {
    padding: 20px 20px 20px 20px;
    height: auto;
    margin-right: 0;
    margin-left: 0;
  }
}
.title-white {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  border-bottom: solid 1px #fff;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
  }
}
.title-black {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #252827;
  position: relative;
  z-index: 222;
  border-bottom: solid 1px #252827;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
  }
}
.title-green {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  border-bottom: solid 1px #006942;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 18px;
  }
}
.text-white {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.text-black {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-img {
  position: absolute;
  right: 200px;
}
.financial-content {
  display: flex;
  @media (max-width: 1165px) {
    flex-direction: column;
  }
  p {
    padding-bottom: 20px;
  }
}
.financial-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
</style>
