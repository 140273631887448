<template>
  <div class="paymentSystem">
    <div class="paymentSystem-wrapper">
      <div class="paymentSystem-content">
        <div>
          <h2>PAYMENT SYSTEM</h2>
          <div class="block">
            <p class="text">
              A payment system is a set of institutions, markets, and supporting
              infrastructure for financial markets with regard to payments,
              clearing, and settlement of financial instruments. As a component
              of this system, the clearing and settlement infrastructure plays
              an essential role related to financial stability and overall
              economic development. Moreover, the effective implementation of
              the monetary policy is based on the existence of a safe and
              efficient payment system.
            </p>
            <p class="text">
              A payment system is a set of instruments, procedures, and rules
              for the transfer of funds among participants in the system based
              on an agreement between the participants in the system and the
              system operator. The funds are transferred through a special
              technical infrastructure.
            </p>
            <p class="text">
              The Central Reserve Authority of Western Sahara plays a principal
              role in promoting a normal, safe, and efficient functioning of the
              payments system. To this end, the Central Reserve Authority of
              Western Sahara acts as the operator, overseers, catalyst, and
              reformer of the system.
            </p>
            <p class="text">
              Payment System is an important part of the economic and financial
              infrastructure of the Country. It handles financial transactions
              in a quick, safe, and reliable manner, which is the prerequisite
              of the smooth functioning of financial and money markets,
              supporting the stability and efficiency of the economy of Western
              Sahara. The Payment system consists of large interbank payment
              systems as well as retail payment systems. According to the
              Western Sahara Law on “Payment System and Payment Services”,
              payment services are provided to the customers by the payment
              service providers including commercial banks operating in Western
              Sahara, microfinance organizations, and other legal entities
              registered in the Central Reserve Authority of Western Sahara as
              the payment service providers.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.paymentSystem-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
}
.text-bold {
  font-weight: bold;
}

.no-line {
  &::before {
    display: none;
  }
}
.paymentSystem-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
