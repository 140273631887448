<template>
  <div class="main-wrapper">
    <Exchange />
    <MacroPrudentialStrategy />
    <FinalObjective />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import MacroPrudentialStrategy from "./macroPrudentialStrategys.vue";
import FinalObjective from "./finalObjectives.vue";


export default {
  components: {
    Exchange,
    MacroPrudentialStrategy,
    FinalObjective,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
