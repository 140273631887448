<template>
  <div class="step">
    <div class="step-wrapper">
      <div class="step-content">
        <div>
          <h2 class="step-one-title">
            STEP ONE: REVIEW GENERAL INFORMATION ABOUT BUSINESS NAMES
          </h2>
          <div class="block">
            <p class="text">
              Operating a business under a name other than your own is a common
              practice in Western Sahara. It is important that you understand
              what business names are and the obligations for business name
              holders.
            </p>
            <p class="text">
              There are a number of important things that you must do when you
              use a business name. You must:
            </p>
            <div>
              <span class="text"
                >display your business name certificate at the place of
                business;
              </span>
              <span class="text"
                >put your actual name on written documents associated with the
                business (e.g. invoices, receipts, demands for debts);
              </span>
              <span class="text"
                >renew your business name registration every year;
              </span>
            </div>
          </div>
        </div>
        <div>
          <h2 class="step-two-title">STEP TWO: CHOOSE YOUR BUSINESS NAME</h2>
          <div class="block">
            <p class="text">
              Most names can be used in Western Sahara, SADR, however there are
              some important exceptions that you should be aware of.
            </p>
            <p class="text">
              A proposed business name will be refused if it is identical (or
              similar enough to deceive or mislead) to a:
            </p>
            <div>
              <span class="text">company name; </span>
              <span class="text"
                >business name registered in the past five years;
              </span>
              <span class="text">reserved business name; </span>
              <p class="text margin-top-botton">
                Other grounds for refusal are where the proposed name:
              </p>
              <span class="text"
                >suggests a connection with government or other public
                bodies;</span
              >
              <span class="text"
                >contains the word ‘co-operative’ or ‘building society’;</span
              >
            </div>
          </div>
        </div>
        <div>
          <h2 class="step-three-title">
            STEP THREE: RESERVE YOUR BUSINESS NAME
          </h2>
          <div class="block">
            <p class="text">
              You are able to put a business name on hold for six months ahead
              of applying to register the business name. After this time, you
              must either give up the name or reserve the name again. However,
              you should note that you may only reserve a proposed business name
              twice over a five-year period.
            </p>
            <p class="text">
              Reservation is easily done online through the registry services
              tab above. There is a fee to reserve a business name that is EUR
              150. Please note that if you continue to register the business
              name an additional fee will be payable.
            </p>
          </div>
        </div>
        <div>
          <h2 class="step-four-title">
            STEP FOUR: REGISTER YOUR BUSINESS NAME
          </h2>
          <div class="block">
            <p class="text">
              The fee for registering a business name is included in the cost of
              the application fee. So it is quicker and cheaper for you to apply
              for the business name directly.
            </p>
            <p class="text">
              Registering a business name is simple and can be easily done
              online.
            </p>
          </div>
        </div>
        <div>
          <h2 class="step-five-title">
            STEP FIVE: KEEPING YOUR DETAILS UP TO DATE
          </h2>
          <div class="block">
            <p class="text">
              Every year business name registrations must be renewed. Renewing
              your business name is important so that your details are up to
              date and the CRA knows that you are still operating under a
              business name. Renewing your business name provides you with
              further protection against others who may attempt to operate using
              your business’ goodwill and reputation. It also means that older
              names that are not being used anymore can be used by new
              businesses.
            </p>
            <p class="text">
              It is required to renew your business name within the ‘anniversary
              period’. The anniversary period is the same month as the month you
              first registered your business name. For example, if you
              registered a business name on 1 November 2020, you can renew the
              name anywhere between 1 November and 30 November 2021. You must
              renew the name every year during these dates.
            </p>
            <p class="text">
              This can easily be done through the online registry, and only
              takes a few minutes – especially if you have no changes to make.
              You have one month to submit your annual renewal. After that time
              you will have to pay a late fee. If your annual renewal is more
              than 2 months late, your business name will expire and you will
              have to register a new business name.
            </p>
            <p class="text">There is a fee for annual renewals.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.step-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 120px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
    padding-bottom: 35px;
  }
}
.block {
  padding: 50px 50px 0px 400px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 400px;
  margin: 0;
  padding-top: 85px;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-top: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
}
.step-one-title {
  position: relative;
  &::before {
    content: "STEP ONE";
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    text-transform: uppercase;
    color: #dae4e0;
    position: absolute;
    left: 137px;
    z-index: -22;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.step-two-title {
  position: relative;
  &::before {
    content: "STEP TWO";
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    text-transform: uppercase;
    color: #dae4e0;
    position: absolute;
    left: 137px;
    z-index: -22;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.step-three-title {
  position: relative;
  &::before {
    content: "STEP three";
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    text-transform: uppercase;
    color: #dae4e0;
    position: absolute;
    left: 137px;
    z-index: -22;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.step-four-title {
  position: relative;
  &::before {
    content: "STEP four";
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    text-transform: uppercase;
    color: #dae4e0;
    position: absolute;
    left: 137px;
    z-index: -22;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.step-five-title {
  position: relative;
  &::before {
    content: "STEP five";
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    text-transform: uppercase;
    color: #dae4e0;
    position: absolute;
    left: 137px;
    z-index: -22;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.subtitle {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #006942;
  margin-top: 22px;
  margin-bottom: 22px;
  @media (max-width: 740px) {
    font-size: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-skils {
  display: flex;
}
ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 56px;
  &:first-child {
    margin-right: 200px;
    @media (max-width: 1100px) {
      margin-right: 0;
    }
  }
  li {
    list-style-type: none;
    margin-left: 30px;
    margin-bottom: 10px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: 6px;
      left: -23px;
      background: url("../../../assets/brokerageCompanies/ellipse.svg");
    }
  }
}
.step-content {
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.step-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
span {
  display: block;
  padding-bottom: 16px;
  margin-left: 40px;
  position: relative;
  &::before {
    content: "";
    width: 20px;
    height: 2px;
    background: #006942;
    position: absolute;
    top: 11px;
    left: -38px;
  }
}
.margin-top-botton {
  margin-top: 22px;
  margin-bottom: 22px;
}
</style>
