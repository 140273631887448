<template>
  <div class="main-wrapper">
    <CryptoLicense />
    <Cryptocurrency />
    <Requirements />
    <Applying />
  </div>
</template>

<script>
import CryptoLicense from "./cryptoLicense";
import Requirements from "./requirements";
import Applying from "./applying";
import Cryptocurrency from "./cryptocurrency";

export default {
  components: {
    CryptoLicense,
    Cryptocurrency,
    Requirements,
    Applying,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
